@import url('https://fonts.googleapis.com/css2?family=Advent+Pro:wght@100;400;500;700&display=block');

@font-face {
  font-family: 'nattonooto-Regular';
  src: url('./fonts/nattonooto-Regular.otf') format('opentype'),
    /* Open Type Font */ url('./fonts/nattonooto-Regular.svg') format('svg'),
    /* Legacy iOS */ url('./fonts/nattonooto-Regular.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('./fonts/nattonooto-Regular.woff')
      format('woff'),
    /* Modern Browsers */ url('./fonts/nattonooto-Regular.woff2')
      format('woff2'); /* Modern Browsers */
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

body {
  background-color: whitesmoke;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: 'Advent Pro', sans-serif;
}

#title {
  margin-top: 12vh;
  text-align: center;
  font-family: 'nattonooto-Regular';
  font-size: min(10vw, 200px);
  margin-bottom: 7vh;
}

@media screen and (max-width: 400px) {
  #title {
    font-size: 14vw;
    margin-top: 10vh;
  }
}

#video-container {
  max-width: 720px;
  margin: 0 auto 150px;
}

#video {
  display: flex;
  justify-content: center;
  position: relative;
  padding-bottom: 56.25%; /* - 16:9 aspect ratio (most common) */
  /* padding-bottom: 62.5%; - 16:10 aspect ratio */
  /* padding-bottom: 75%; - 4:3 aspect ratio */
  padding-top: 30px;
  height: 0;
  overflow: hidden;
}

#video iframe {
  border: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

#tracks {
  padding: 5%;
  font-family: 'Advent Pro', sans-serif;
  user-select: none;
  font-size: 26px;
  margin-bottom: 100px;
  text-align: center;
}

#tracks.admin {
  padding: 30px;
}

#tracks.admin .track p {
  margin: 4px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

#tracks.admin .version {
  font-size: 2.4em;
  font-weight: 700;
  margin: 20px 0;
}

@media screen and (max-width: 400px) {
  #tracks.admin .version {
    font-size: 1.6em;
  }
}

.track {
  box-sizing: border-box;
  display: inline-block;
  padding: 0 1em;
  border: none;
  margin: 0;
  text-decoration: none;
  font-family: inherit;
  font-size: inherit;
  background: none;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  cursor: pointer;
}

.track:hover,
.track:focus,
.track:active {
  background: none;
}

.track:focus {
  outline: none;
}

#tracks.admin .track {
  display: block;
  padding: 0;
  align-items: center;
  width: 100%;
}

#tracks .track span {
  margin: 0 4px;
}

#tracks .track .date {
  font-size: 1em;
  font-weight: 100;
}

#tracks.admin .track .date {
  margin-right: 1em;
}

#tracks .track.playing {
  box-shadow: inset 0 0 0 4px black;
  transition: box-shadow 500ms cubic-bezier(0, 0.5, 0, 1);
}

#tracks .track .duration {
  font-weight: 500;
  font-size: 1.2em;
}

#tracks .track.played p {
  background-color: rgba(0, 0, 0, 0.07);
  transition: background-color 1s cubic-bezier(0, 0.5, 0, 1);
}

#tracks .track .rating {
  background-color: rgba(0, 0, 0, 0.2);
  padding: 0.5em;
}

#tracks .track .rating.rating-1 {
  background-color: rgb(169 164 4 / 20%);
}

#tracks .track .rating.rating-2 {
  background-color: rgb(10 164 4 / 20%);
}

#tracks .track .notes {
  font-size: 0.5em;
}

#visuals {
  z-index: -1;
  position: fixed;
  top: 0;
  left: -3vw;
  width: 110%;
  font-family: 'nattonooto-Regular';
  font-size: 20px;
  word-break: break-all;
  color: black;
}

.letter {
  opacity: 0.1;
}

.letter-a {
  opacity: 0.06;
}

.letter-t {
  opacity: 0.08;
}

.letter-n {
  opacity: 0.04;
}

.letter-o {
  opacity: 0.1;
}

#bar {
  display: flex;
  justify-content: space-between;
  z-index: 100;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 50px;
  background-color: #00000033;
}

#bar .cti-wrapper {
  flex-grow: 1;
}

#bar .cti-wrapper.loading {
  animation: loopOpacity 0.5s alternate infinite;
  background-color: black;
}

@keyframes loopOpacity {
  from {
    opacity: 0;
  }
  to {
    opacity: 0.2;
  }
}

#bar .cti {
  height: 100%;
  background-color: #00000030;
}

#bar .button {
  width: 50px;
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ddd;
  font-size: 40px;
  font-family: 'Advent Pro', sans-serif;
  font-weight: 100;
  cursor: pointer;
}

.button .icon1,
.button .icon2 {
  transform: scaleY(0.6);
}

#bar .info {
  font-size: 35px;
}

#info {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #00000099;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
}

#info .modal {
  position: relative;
  bottom: 50px;
  box-sizing: border-box;
  width: 500px;
  max-width: 80%;
  background-color: whitesmoke;
  padding: 20px 30px;
  font-size: 18px;
  overflow: auto;
}

@media screen and (max-width: 600px) {
  #info .modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    max-width: 100%;
    height: 100%;
    padding-bottom: calc(50px + 20px);
  }
}

#info a {
  color: black;
}

#close {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 40px;
  height: 40px;
  font-size: 30px;
  cursor: pointer;
  text-align: center;
}

@media screen and (max-width: 400px) {
  #close {
    position: fixed;
    background-color: rgba(245, 245, 245, 0.8);
    border-radius: 20px;
  }
}
